<template>
  <div style="margin-top: 50px">
    <!-- 轮播图 -->
    <el-carousel arrow="always" height="550px">
      <el-carousel-item v-for="(item, index) in siwperList" :key="index">
        <img :src="item.img" alt="" style="height: 100%; width: 100%" />
      </el-carousel-item>
    </el-carousel>
    <el-divider class="el-divider-active"></el-divider>
    <div class="section" v-loading="loading">
      <div class="section-content">
        <div class="content-summary">
          <div class="summary-left">
            <p class="title">赢信汇通（雅安）集团发展有限公司</p>
            <p class="eTitle">ABOUT US</p>
            <p class="content">
              赢信汇通（雅安）集团发展有限公司，是一家规模化自主研发设计、制造、销售、服务为一体的现代化企业。
              公司成立于2019年6月，注册资本5000万元，项目总投资人民币3亿元，一期项目占地121亩，厂房建筑面积45000平方米，专业生产高端智能交通装备：主要包括重卡及商用车的制动鼓、轮毂、刹车盘、刹车卡钳、减速器壳体、车轴、驱动桥总成等汽车关键零部件，产品主要供往：上汽红岩、东风德纳、陕汽汉德、一汽解放及柳汽方胜等国内知名汽车生产巨头。
              一期工程，主要分为四大板块，分别为：位于公司西南方向的大楼为研发中心、综合办公楼：是集新产品研发、设计及生产行政管理为一体的现代化综合办公大楼；
              3号厂房为铸造车间，主要设备为：4台6T中频电炉（含自动打渣机械手2台）、1台100T全自动混砂机、德国HWS全自动静压造型线1套、全自动铁水转运系统1套、倾转式浇注机2台；1号厂房为现代化机加工车间，主要设备为：日本法兰科智能机器人27组、全自动数控立车42台（沈阳机床）、全自动数控加工中心24台（宝鸡机床）、全自动动平衡检测机12台（湖北三联），及全自动清洗喷涂线2条等；2号厂房为公司物流仓储及产品综合检测线车间；
              为了打造优质的汽车零部件生产线，公司本着“质量至上”的原则，大力投入了大量国内外高尖端检测设备，主要设备有德国全通道光谱分析仪、三坐标测量仪、光谱分析仪、超声波探伤仪等高精度智能检测设备。
              在市、区领导及园区领导的大力支持及关怀下，曼尼威斯项目进展十分顺利，前期主体建设已基本完工，铸造车间已顺利完成点火测试，元月份将进入小批量生产状态，正常投产后，预计可实现日产值300万元、年产值超8亿元，年创税4000万元左右，带动解决当地就业300人左右。
            </p>
          </div>
          <div class="summary-right">
            <img src="http://system.yxht.net/yaan_1.png" alt />
          </div>
        </div>
        <div class="content-summary">
          <div class="summary-left">
            <p class="title">四川赢信汇通实业有限公司</p>
            <p class="eTitle">ABOUT US</p>
            <p class="content">
              四川赢信汇通实业有限公司成立于2017年8月，位于成都市大邑县工业园区，占地241亩。项目总投资12亿元，于2017年11月28号开工建设，2018年9月一期投产，2020年年底全面投产，投产后预期年产值达30亿元，创税1.2亿元。公司集研发、生产、销售、服务为一体，专业生产：商用车和乘用车制动鼓、轮毂、刹车盘、减速器、差速器、车轴、驱动桥总成、半挂车及铁路机车、动车组、城轨地铁轮对等重要制动零部件。
              公司以铸造卓越品质为经营理念，聚集大批专业技术人才，专注刹车制动系统。采用全自动铸造工艺生产线，结合全自动数控加工中心、智能机器人、自动总装流水线、三坐标测量仪、光谱分析仪、超声波探伤仪等高精度智能加工及检测设备，确保产品品质达到国际一流水平。
            </p>
          </div>
          <div class="summary-right">
            <img src="http://system.yxht.net/Winletter_1.png" alt />
          </div>
        </div>
        <el-divider class="el-divider-active"></el-divider>
        <div class="content-summary">
          <div class="summary-left">
            <p class="title">主要客户</p>
            <p class="eTitle">ABOUT US</p>
            <p style="color: #14679f; font-size: 14px; padding-bottom: 20px">
              公司现有商用车及乘用车制动器零部件主要客户
            </p>
            <div>1.上汽红岩汽车有限公司</div>
            <div>2.陕西汉德车桥有限公司</div>
            <div>3.方盛车桥(柳州)有限公司</div>
            <div>4.方盛车桥(合肥)有限公司</div>
            <div>5.东风德纳车桥有限公司</div>
            <div>6.东风柳州汽车有限公司</div>
            <div>7.中国重型汽车集团有限公司</div>
            <div>8.广东华劲车桥有限公司</div>
            <div>9.国内整车生产厂</div>
          </div>
          <div class="summary-right">
            <img src="http://system.yxht.net/yaan_2.png" alt />
          </div>
        </div>
        <el-divider class="el-divider-active"></el-divider>
        <div class="content-culture">
          <h3>企业文化</h3>
          <p style="padding-top: 20px">我们只专注一件事情</p>
          <span>目标：致力于成为重卡制动领域的领航者、制动鼓标准的制定专家</span>
          <span>合作：厚德人本、精诚合作、创新卓越、无限多赢</span>
          <span>愿景：做汽车配件精品、创赢信汇通品牌</span>
          <span>文化：诚信立足、共赢发展、汇聚资源、通达天下</span>
        </div>
        <el-divider class="el-divider-active"></el-divider>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      // 轮播图数据
      siwperList: [
        {
          img: "http://system.yxht.net/yxht20.png",
        },
        {
          img: "http://system.yxht.net/yxht3.png",
        },
        {
          img: "http://system.yxht.net/yxht4.png",
        },
        {
          img: "http://system.yxht.net/yxht19.png",
        },
        {
          img: "http://system.yxht.net/yxht18.png",
        },
        {
          img: "http://system.yxht.net/yxht5.png",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {},
};
</script>

<style scoped lang='scss'>
.swiperList {
  height: 400px;
}
.w {
  width: 670px;
  margin: 0 auto;
}
.swiperImgs {
  width: 100%;
  height: 100%;
}
.section {
  width: 100%;
  &-content {
    width: 1240px;
    margin: 0 auto;
    background-color: #fff;

    .content-summary {
      //height: 500px;
      display: flex;
      justify-content: space-around;
      padding: 100px 0;
      .summary-left {
        width: 600px;
        div {
          font-size: 14px;
          line-height: 30px;
        }
        .title {
          font-size: 25px;
          color: #e13834;
        }
        .eTitle {
          font-size: 17px;
          color: #e13834;
          padding: 20px 0;
        }
        .content {
          color: #14679f;
          font-size: 14px;
          text-indent: 25px;
          line-height: 30px;
        }
      }

      .summary-right {
        width: 400px;
        height: 310px;
        border: 2px solid #1d42b9;
        animation: imgboxkey 4s infinite;
        border-radius: 10px;
        margin-top: 80px;
        text-align: center;

        img {
          width: 360px;
          height: 270px;
          margin-top: 20px;
          border-radius: 10px;
          animation: imgbo 4s infinite;
        }
      }
    }

    //发展历程
    .content-course {
      padding: 50px 0;
      .course-time {
        width: 1000px;
        height: 400px;
        margin: 20px auto;
        .swiper-container {
          height: 100%;
        }
        .time-show {
          width: 700px;
          height: 100%;
          margin: 0 auto;
          display: flex;
          .time-show-item {
            width: 350px;
            height: 100%;
            overflow: hidden;
            display: flex;
            flex-direction: column;

            .item-top,
            .item-bottom {
              height: 190px;
            }
            .item-bottom {
              // display: flex;
              // align-content: center;
              .item-bottom-content {
                background-color: #1667a0;
                margin: 20px 0;
                p {
                  color: #fff;
                  text-align: center;
                  padding: 15px;
                }
              }
            }
          }
        }
      }
    }

    //企业文化
    .content-culture {
      height: 350px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: flex-start;
      padding-left: 60px;
      background-color: #c2daeb;
      border-radius: 20px;
      padding-top: 40px;
      h3 {
        color: #3c6088;
        font-size: 24px;
      }
      p {
        color: #3c6088;
        font-size: 18px;
        padding: 5px 0;
      }
      span {
        font-weight: 500;
        line-height: 36px;
        font-size: 14px;
        padding: 5px 0;
      }
    }
  }
}
@keyframes imgboxkey {
  0% {
    border: solid rgb(29, 66, 185) 2px;
  }
  40% {
    border: solid rgb(255, 255, 255) 2px;
  }
  60% {
    border: solid rgb(255, 255, 255) 2px;
  }
  100% {
    border: solid rgb(29, 66, 185) 2px;
  }
}

@keyframes imgbo {
  0% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0px 0px 10px 5px #ababab;
  }
  100% {
    transform: scale(1);
    box-shadow: 0px 0px 0px 0px #ababab;
  }
}
</style>